.get-started {
    --button-width: 12rem;
    --input-width: 20rem;
}

a:after {
    transition: all 0.2s ease-in-out;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: -1;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    // background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3));
    @apply rounded-xl;
}

a:hover:before,
a:hover:after {
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

button {
    width: var(--button-width);
}

form {
    @apply absolute z-10 bg-white rounded-xl flex-row items-center;

    pointer-events: none;
    display: flex;

    transition: all 0.2s ease;

    padding: 0;
    top: 0;
    left: 0;

    width: var(--button-width);
    overflow: hidden;
}

form input {
    outline: none;
    transition: width 0.2s ease;
    width: 0;
    color: #333;
}

form.show {
    display: flex;
    pointer-events: initial;
    --padding: 0.5rem;

    padding: var(--padding);
    padding-left: calc(var(--padding) * 2);
    width: calc(var(--input-width) + var(--button-width) + var(--padding) * 3);
    top: -0.5rem;
    left: -10rem;
}

form.show input {
    display: flex;
    width: 20rem;
}