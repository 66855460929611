@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: 'Inter Tight';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/Font/InterTight-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Abril Fatface';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/AbrilFatface-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Alegreya';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Alegreya-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Anton';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Anton-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Arimo';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Arimo-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Bebas Neue';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Cabin';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Cabin-VariableFont_wdth.ttf') format('truetype');
}

@font-face {
	font-family: 'IBM Plex Sans';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Karla';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Karla-VariableFont_wght.ttf') format('truetype');
}


@font-face {
	font-family: 'Lato';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lilita One';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/LilitaOne-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lobster';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Lobster-Regular.ttf') format('truetype');
}


@font-face {
	font-family: 'Merriweather';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}


@font-face {
	font-family: 'Nanum Gothic';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/NanumGothic-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Nunito';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}


@font-face {
	font-family: 'Oswald';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Pacifico';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Pacifico-Regular.ttf') format('truetype');
}


@font-face {
	font-family: 'Poppins';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Raleway';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Ubuntu';
	font-weight: 100 700;
	font-display: flex;
	font-style: normal;
	src: url('../assets/fonts/Ubuntu-Regular.ttf') format('truetype');
}




:root {
	color: #F3F4EF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

body::-webkit-scrollbar {
	display: none;
	-webkit-appearance: none;
}

body {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overscroll-behavior: none;
}

html {
	scrollbar-width: none;
	scroll-behavior: smooth;
}

html,
body {
	overflow-x: hidden;
}

.react-flow__attribution {
	display: none;
}

@layer utilities {
	.text-shadow {
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	.text-shadow-md {
		text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
	}

	.text-shadow-lg {
		text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
	}

	.text-shadow-none {
		text-shadow: none;
	}
}

@layer text {
	.h1 {
		@apply text-[40px] font-semibold leading-normal
	}

	.h2 {
		@apply text-[32px] font-semibold leading-normal
	}

	.h3 {
		@apply text-[32px] font-semibold leading-normal
	}

	.h4 {
		@apply text-[22px] font-semibold leading-normal
	}

	.h5 {
		@apply text-[18px] font-semibold leading-normal
	}

	.h6 {
		@apply text-[16px] font-semibold leading-normal
	}

	.h7 {
		@apply text-[14px] font-semibold leading-normal
	}

	.h8 {
		@apply text-[13px] font-semibold leading-normal
	}

	.body-big {
		@apply text-[15px] leading-normal
	}

	.body-medium {
		@apply text-[13px] leading-normal
	}

	.body-small {
		@apply text-[12px] leading-normal
	}
}

.ant-input:last-child {
	/*border-left: 0;*/
}

.ant-btn-primary {
	@apply bg-red
}

.ant-dropdown-menu {
	display: block;
}

.default-page-sizes {
	height: 44px;
	width: 100%;
}

.ant-tabs-nav {
	margin: 0 !important;
}

.company-name:first-letter {
	text-transform: uppercase;
}

.shortHeadlines {
	display: -webkit-box;
	max-width: 320px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.longHeadlines {
	display: -webkit-box;
	max-width: 200px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.css-1qbs0lq-menu {
	z-index: 99999 !important;
}

.bg-warn {
	background-color: rgb(245, 158, 11);
}

.ant-select-dropdown {
	z-index: 9999;
}

.ant-input-searh input {
	background: #ffffff;
}