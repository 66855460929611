.ant-tabs-tab-btn {
    display: flex !important;
    align-items: center;
    font-weight: 500;
}

.custom-right-arrow {
    position: absolute !important;
    right: 10px;
    top: 45%;
    z-index: 1;
    opacity: 0.8;
    transform: translate(0, -50%) rotate(-90deg);
    opacity: 0.9;
}

.custom-left-arrow {
    position: absolute !important;
    left: 10px;
    top: 45%;
    z-index: 1;
    transform: translate(0, -50%) rotate(90deg);
    opacity: 0.9;
}