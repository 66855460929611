h1 {
    background: linear-gradient(to bottom, #fff, pink 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

h1:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    filter: blur(150px);
    opacity: 0.2;
}