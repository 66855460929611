.train li {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    min-width: 10rem;
    border-radius: 4rem;
    border: 1px solid gray;
    color: white;
    @apply font-display;
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
    flex: 1 0 auto;
    position: relative;
    margin-left: 2rem;
    background: rgba(255, 255, 255, 0.05);
}

.train li:before,
.train li:after {
    content: '';
    position: absolute;
    left: 30px;
    right: 30px;
    height: 1px;

    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.25),
        rgba(255, 255, 255, 0)
    );
    z-index: 1;
}
.train li:before {
    top: -1px;
}
.train li:after {
    bottom: -1px;
}

.train li > span {
    position: relative;
    letter-spacing: 0.025em;
}

.train li > :global(svg) {
    bottom: 6px;
    left: 19px;
    width: 1.5rem;
    height: 1.5rem;
    fill: #ccc;
    stroke: #ccc;
}